import React from "react"
import {
  IoColorWandOutline,
  IoCodeWorkingOutline,
  IoLeafOutline,
} from "react-icons/io5"
export default [
  {
    id: 1,
    icon: <IoCodeWorkingOutline className="interest__icon" />,
    title: "Storybook",
    text: `Studying how to use Storybook to create component design systems.`,
  },
  {
    id: 2,
    icon: <IoColorWandOutline className="interest__icon" />,
    title: "CSS animations",
    text: `Rebuilding Juntec's website using CSS animations.`,
  },
  {
    id: 3,
    icon: <IoLeafOutline className="interest__icon" />,
    title: "Sustainable web design",
    text: `Learning how to measure and reduce a website’s environmental impact.`,
  },
]
