import React, { useState } from "react"
import { IoChevronForwardCircleOutline } from "react-icons/io5"
import { graphql, useStaticQuery } from "gatsby"
import PrimaryButton from "./PrimaryButton"
const query = graphql`
  {
    allStrapiJob(sort: { fields: strapi_id, order: DESC }) {
      nodes {
        Company
        Date
        Position
        Description {
          Content
          id
        }
      }
    }
  }
`
const Experience = ({ showLink }) => {
  const data = useStaticQuery(query)
  const {
    allStrapiJob: { nodes: jobs },
  } = data
  const [value, setValue] = useState(0)
  const { Company, Position, Date, Description } = jobs[value]
  return (
    <section className="experience__section">
      <div className="section-center">
        <div className="title__section--center">
          <h2>Experience</h2>
          <div className="underline__title--resume"></div>
        </div>
        <div className="experience__container--center">
          <div className="experience__button--jobs">
            {jobs.map((item, index) => {
              return (
                <button
                  key={index}
                  className={
                    index === value
                      ? "experience__button--company active-btn"
                      : "experience__button--company"
                  }
                  onClick={() => setValue(index)}
                >
                  {item.Company}
                </button>
              )
            })}
          </div>
          <article className="resume__text">
            <h3>{Position}</h3>
            <h4>{Company}</h4>
            <p className="resume__date">{Date}</p>
            {Description.map(item => {
              return (
                <div key={item.id} className="resume__description">
                  <IoChevronForwardCircleOutline className="experience__icon" />
                  <p>{item.Content}</p>
                </div>
              )
            })}
          </article>
        </div>
        {showLink && (
          <div className="btn--center">
            <PrimaryButton
              buttonText="See resume"
              url="/resume"
              aria-label="my resume button"
            ></PrimaryButton>
          </div>
        )}
      </div>
    </section>
  )
}

export default Experience
