import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Interests from "../components/Interests"
import Experiences from "../components/Experience"
import Showcases from "../components/Showcases"

import { graphql } from "gatsby"
const IndexPage = ({ data }) => {
  const {
    allStrapiProject: { nodes: projects },
  } = data
  return (
    <>
      <Layout title="Home | Rayane Silva">
        <Hero />
        <Interests />
        <Experiences showLink />
        <Showcases showcases={projects} />
      </Layout>
    </>
  )
}
export const query = graphql`
  {
    allStrapiProject(filter: { Featured: { eq: true } }) {
      nodes {
        Title
        Description
        Url
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`
export default IndexPage
