import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Showcase = ({ Title, Image, Description }) => {
  const image = getImage(Image.localFile)
  return (
    <article className="showcase__card">
      <GatsbyImage image={image} className="showcase__card--img" alt={Title} />
      <div className="showcase__card--text">
        <h3 className="showcase__card--title">{Title}</h3>
        <p className="showcase__card--text-body">{Description}</p>
      </div>
    </article>
  )
}

export default Showcase
