import React from "react"
import interests from "../constants/interests"
const Interests = () => {
  return (
    <section className="interests__section">
      <div className="section-center">
        <h2 className="title__section">
          <span className="title__bg--yellow">
            What I'm up to <span className="interest__title">now!</span>
          </span>
        </h2>
        <div className="interests__container">
          {interests.map(interest => {
            const { id, icon, title, text } = interest
            return (
              <article key={id} className="interest__card">
                <div className="interest__icon--bg"> {icon}</div>
                <h3>{title}</h3>
                <div className="underline"></div>
                <p>{text}</p>
              </article>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Interests
