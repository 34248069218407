import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { IoLocationOutline } from "react-icons/io5"
import SecondaryButton from "./SecondaryButton"

const Hero = () => {
  return (
    <section className="hero__section">
      <article className="hero__text">
        <div className="hero__text--kicker">
          <h3>Hello</h3>
          <div className="underline"></div>
        </div>
        <div className="hero__header">
          <h1 className="hero__header--title">
            <span>I'm </span>
            <span className="hero__header--span">Rayane </span>
          </h1>
          <div className="hero__subtitle">
            <ul className="hero__list">
              <li className="hero__list--item">Fullstack</li>
              <li className="dot-shape"></li>
              <li className="hero__list--item">Frontend</li>
              <li className="dot-shape"></li>
              <li className="hero__list--item">
                <span className="hero__icon--geo">
                  <IoLocationOutline></IoLocationOutline>
                </span>
                <span className="hero__span--local">Belgium</span>
              </li>
            </ul>
          </div>
        </div>
        <p className="hero__header--desc">
          I have experience in information management as well as in
          web-development. I am expanding my knowledge with JavaScript
          frameworks and sustainable web design.
        </p>
        <div className="hero__btn">
          <SecondaryButton
            buttonText="About me"
            aria-label="about page button"
            url="/about"
          ></SecondaryButton>
        </div>
      </article>
      <div className="hero__img">
        <StaticImage
          src="../assets/data_science.svg"
          alt="data science illustration"
          className="hero__img--svg"
          loading="eager"
        />
      </div>
    </section>
  )
}

export default Hero
