import React from "react"
import Showcase from "./Showcase"
import SecondaryButton from "./SecondaryButton"
import { gsap } from "gsap"
import { useEffect } from "react"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const Showcases = ({ showcases }) => {
  useEffect(() => {
    gsap.to("showcases__text", {
      yPercent: 0,
      ease: "none",
      scrollTrigger: {
        trigger: ".showcases__section",
        scrub: true,
      },
    })

    gsap.to(".showcases__card--container", {
      yPercent: 15,
      ease: "none",
      scrollTrigger: {
        trigger: ".showcases__section",
        scrub: true,
      },
    })
  }, [])
  return (
    <section className="showcases__section">
      <div className="showcases__text">
        <div className="showcases__text--header">
          <h3 className="showcases__text--kicker">
            <span className="title__bg--yellow">Portfolio</span>
          </h3>
          <div className="showcases__text--title">
            <div className="underline"></div>
            <h2>
              Check out some of my {""}
              <span className="showcases__text--span">work</span>
            </h2>
          </div>
        </div>

        <div className="showcases__btn--left">
          <SecondaryButton
            buttonText="View projects"
            url="/projects"
            aria-label="project page button"
          ></SecondaryButton>
        </div>
      </div>
      <div className="showcases__card--container">
        <div className="card">
          {showcases.map((showcase, index) => {
            return <Showcase key="id" index={index} {...showcase} />
          })}
        </div>
      </div>
    </section>
  )
}

export default Showcases
